import React from 'react'
import statementLove from '../../assets/images/statement-love.svg'
import statementIdeia from '../../assets/images/statement-ideia.svg'
import statementUnion from '../../assets/images/statement-union.svg'
import statementHands from '../../assets/images/statement-hands.svg'
import statementPeople from '../../assets/images/statement-people.svg'
import statementGroup from '../../assets/images/statement-group.svg'

const items = [
  {
    title: () => (
      <h2>
        Inovação e alta reputação no
        <span> ecossistema de seguros</span>
      </h2>
    ),
    img: statementIdeia,
    imgAlt: 'Inovação e alta reputação no ecossistema de seguros - Pottencial',
    pFst: 'Somos receptivos a novas ideias.',
    pScd: 'Agimos com ética e responsabilidade.',
    pThr: 'Baseamos somente nas ações dos nossos concorrentes.',
    pFr: 'Acreditamos que o lucro está acima da ética.',
  },
  {
    title: () => (
      <h2>
        <span>Colaboração </span>
        e trabalho em equipe
      </h2>
    ),
    img: statementPeople,
    imgAlt: 'Colaboração e trabalho em equipe - Pottencial',
    pFst: 'Procuramos soluções, não culpados.',
    pScd: 'Buscamos sinergia entre os times.',
    pThr: 'Deixamos de envolver o time em decisões relevantes.',
    pFr: 'Apontamos erros e não celebramos pequenas conquistas.',
  },
  {
    title: () => (
      <h2>
        Gente que
        <span> abraça desafios</span>
      </h2>
    ),
    img: statementUnion,
    imgAlt: 'Gente que abraça desafios - Pottencial',
    pFst: 'Nos sentimos motivados para o desenvolvimento de novas soluções.',
    pScd: 'Antecipamos os problemas e as soluções.',
    pThr: 'Não criamos metas desafiadoras e inalcançáveis.',
    pFr: 'Deixamos de nos comprometer com o propósito.',
  },
  {
    title: () => (
      <h2>
        Entrega de
        {' '}
        <span>valor</span>
        {' '}
        contínua para experimentar e aprender
      </h2>
    ),
    img: statementHands,
    imgAlt: 'Entrega de valor contínua para experimentar e aprender - Pottencial',
    pFst: 'Nos preocupamos em garantir a entrega e a geração de resultados.',
    pScd: 'Buscamos aprender com os erros.',
    pThr: 'Exigir que a primeira entrega seja de excelência.',
    pFr: 'Desconsideramos feedback dos nossos clientes.',
  },
  {
    title: () => (
      <h2>
        Comunicação e
        {' '}
        <span>transparência</span>
      </h2>
    ),
    img: statementGroup,
    imgAlt: 'Comunicação e transparência - Pottencial',
    pFst: 'Nos preocupamos em dizer sempre a verdade.',
    pScd: 'Praticamos a escuta ativa.',
    pThr: 'Calamos frente as dificuldades.',
    pFr: 'Não praticamos a cultura do feedback.',
  },
]

const Statements = () => (
  <>
    <div className="statement">
      <div className="statement-fst">
        <div className="col large-12 center">
          <h4>Statements</h4>
        </div>

        <div className="col small-12 large-6">
          <div>
            <h2>
              Foco no
              <span> Cliente</span>
            </h2>
          </div>
          <div />
          <div>
            <img src={statementLove} alt="Foco no cliente - Pottencial" />
          </div>
        </div>

        <div className="col small-12 large-6">
          <div className="statement-block-info">
            <p>O que a gente faz?</p>
            <p>Oferecemos a melhor experiência aos nossos clientes.</p>
            <p>Priorizamos aquilo que gera valor.</p>
          </div>
          <div className="statement-block-info-gray">
            <p>O que a gente não faz?</p>
            <p>Deixamos de visualizar o problema pela ótica do cliente.</p>
            <p>Deixamos de ter o domínio sobre nossos produtos.</p>
          </div>
        </div>
      </div>

      {items.map((item, index) => (
        <div key={index}>
          <div className="statement-scd">
            <div className="col small-12 large-6">
              <div>
                {item.title()}
              </div>
              <div />
              <div>
                <img src={item.img} alt={item.imgAlt} />
              </div>
            </div>

            <div className="col small-12 large-6">
              <div className="statement-block-info">
                <p>O que a gente faz?</p>
                <p>{item.pFst}</p>
                <p>{item.pScd}</p>
              </div>
              <div className="statement-block-info-gray">
                <p>O que a gente não faz?</p>
                <p>{item.pThr}</p>
                <p>{item.pFr}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </>
)

export default Statements
