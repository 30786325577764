import React from 'react'
import '../../../assets/styles/header.scss'
import PropTypes from 'prop-types'
import Letter from './Letter'
import HeaderDefault from '../../Header'

const Header = ({ terms }) => (
  <>
    <HeaderDefault />
    <div className="banner banner-glossary">
      <div className="container">
        <h1>
          Glossário
          <br />
          {' '}
          da Agilidade
        </h1>
      </div>
    </div>
    <div className="optionsContainer">
      <div className="container">
        <Letter terms={terms} />
      </div>
    </div>
  </>
)

Header.propTypes = {
  terms: PropTypes.array.isRequired,
}

export default Header
