import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Body from '../components/Body'
import '../assets/styles/header.scss'

const Home = () => (
  <main>
    <Header menuWhite />
    <div className="banner">
      <div className="container">
        <h1>Manifesto</h1>
        <h1>ágil</h1>
      </div>
    </div>
    <Body />
    <Footer />
  </main>
)

export default Home
