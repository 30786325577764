import React from 'react'
import backArrow from '../../assets/images/back-arrow.svg'
import arrow from '../../assets/images/arrow.svg'

const SubBanner = () => (
  <div className="subbanner">
    <div className="subbannerarrow center">
      <img src={backArrow} alt="Manifesto Ágil - Pottencial" />
      <img className="arrow" src={arrow} alt="" />
    </div>

    <div className="subbannerparagraph">
      <p>
        Vivemos numa jornada de expansão contínua onde os princípios
        da agilidade orientam pessoas apaixonadas pela entrega de
        produtos inovadores, explorando possibilidades com intensa
        curiosidade e transformando o ecossistema de seguros.
      </p>
    </div>
  </div>
)

export default SubBanner
