import React from 'react'
import SubBanner from './SubBanner'
import Statements from './Statements'
import Guidelines from './Guidelines'
import Facts from './Facts'

const Body = () => (
  <>
    <div className="main container">
      <SubBanner />
      <Statements />
      <Guidelines />
      <Facts />
    </div>
  </>
)

export default Body
