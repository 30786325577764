import React from 'react'
import PropTypes from 'prop-types'

const Term = ({ content }) => {
  const { title, desc, images } = content
  return (
    <div className="item">
      <h2 className="title">{title}</h2>
      <p dangerouslySetInnerHTML={{ __html: desc }} />
      {
        images.map((image, index) => (
          <div key={index} className="image-container">
            <img src={image.url} alt={title} />
            {image.caption && <p className="imageCaption">{image.caption}</p>}
          </div>
        ))
      }
    </div>
  )
}

Term.propTypes = {
  content: PropTypes.object.isRequired,
}

export default Term
