import React from 'react'
import icoMark from '../../assets/images/ico-mark.svg'

const items = [
  {
    text: 'Organizações ágeis são aquelas que são rápidas em responder à mudanças no mercado ou ambiente.',
  },
  {
    text: 'A agilidade é uma cultura.',
  },
  {
    text: 'Agilidade é resolver os problemas de nossos clientes.',
  },
  {
    text: 'Agilidade exige disciplina.',
  },
  {
    text: 'Uma empresa ágil deve voltar seu esforço de experimentação e aprendizado para ouvir e gerar valor para o cliente.',
  },
  {
    text: 'Ser ágil é saber navegar em um ambiente completamente instável.',
  },
  {
    text: 'Ser ágil passa também por melhorar a experiência do usuário.',
  },
]

const Facts = () => (
  <>
    <div className="facts">
      <div className="col large-12 center">
        <h4>
          Mitos e
          {' '}
          <span>Fatos</span>
        </h4>
      </div>

      <div className="facts-sub">
        <div className="col small-12 large-6">
          <div>
            <span>X</span>
            Agilidade significa rapidez.
          </div>
          <div>
            <span>X</span>
            A Agilidade ocorre somente nos times da TI.
          </div>
          <div>
            <span>X</span>
            A agilidade é um processo.
          </div>

          <div>
            <span>X</span>
            Métodos ágeis significa não ter que criar documentação.
          </div>
          <div>
            <span>X</span>
            Entregar muitas features significa ser ágil.
          </div>
          <div>
            <span>X</span>
            Aplico o scrum, portanto sou ágil!
          </div>

          <div>
            <span>X</span>
            Ser ágil significa não ter data para entregar algo.
          </div>
          <div>
            <span>X</span>
            O PO não faz parte do time.
          </div>
          <div>
            <span>X</span>
            Ser ágil é não ter que se preocupar com o design.
          </div>
        </div>

        <div className="col small-12 large-6">
          {items.map((item, index) => (
            <div key={index}>
              <div className="facts-items">
                <div>
                  <img src={icoMark} alt="" />
                </div>
                <div>
                  <span>
                    {item.text}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </>
)

export default Facts
