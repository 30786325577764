/* eslint-disable react/button-has-type */
import React, { useCallback, useEffect, useState } from 'react'

import useWindowSize from '../../hooks/useWindowSize'
import logo from '../../assets/images/logo.svg'
import logoOrange from '../../assets/images/logo-orange.svg'
import iconMenu from '../../assets/images/icon-menu.svg'
import iconMenuOrange from '../../assets/images/icon-menu-orange.svg'
import iconClose from '../../assets/images/menu-close.svg'
import iconPessoa from '../../assets/images/icon-pessoa.svg'
import iconEmpresa from '../../assets/images/icon-empresa.svg'
import iconCorretor from '../../assets/images/icon-corretor.svg'

import '../../assets/styles/header.scss'
import '../../assets/styles/menu.scss'

// eslint-disable-next-line react/prop-types
const Header = ({ menuWhite }) => {
  const windowSize = useWindowSize()
  const [activeMenu, setActiveMenu] = useState()
  const [menuThree, setMenuThree] = useState(false)
  const [whiteMenu, setWhiteMenu] = useState(false)

  useEffect(() => {
    if (menuWhite) {
      setWhiteMenu(true)
    }
  }, [menuWhite])

  const handleWhiteMenu = useCallback(() => {
    const position = window.scrollY

    if (position >= 115 || menuWhite) {
      return setWhiteMenu(true)
    }

    return setWhiteMenu(false)
  }, [setWhiteMenu, menuWhite])

  useEffect(() => {
    window.addEventListener('scroll', handleWhiteMenu, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleWhiteMenu)
    }
  }, [handleWhiteMenu])

  const menu3 = [
    {
      id: 0,
      title: 'Como Pessoa',
      link: 'https://paravoce.pottencial.com.br/',
      desc: '',
      imgUrl: iconPessoa,
      icon: iconPessoa,
    },
    {
      id: 1,
      title: 'Como Empresa',
      link: 'https://empresas.pottencial.com.br/',
      desc: '',
      imgUrl: iconEmpresa,
      icon: iconEmpresa,
    },
    {
      id: 2,
      title: 'Como Corretor',
      link: 'https://portalcorretor.pottencial.com.br/',
      desc: '',
      imgUrl: iconCorretor,
      icon: iconCorretor,
    },
  ]

  return (
    <header id="new-main-header">
      <div id="mobile-menu" className={activeMenu ? 'active' : ''}>
        <div className="content-menu">
          <div className="logo">
            <a
              title="Ir para a página principal"
              aria-label="Ir para a página principal"
              href="/"
            >
              <img src={logo} alt="Logo - Pottencial" />
            </a>
            <button
              type="button"
              className="close"
              title="Fechar menu"
              aria-label="Fechar menu"
              onClick={() => setActiveMenu(false)}
            >
              <img src={iconClose} alt="Ícone - Pottencial" />
            </button>
          </div>

          <div className="navigation">
            <ul>
              <li>
                <a
                  title="Ir para a página de Manifesto Ágil"
                  aria-label="Ir para a página de Manifesto Ágil"
                  href="/"
                >
                  Manifesto Ágil
                </a>
              </li>
              <li>
                <a
                  title="Ir para a página de Glossário"
                  aria-label="Ir para a página de Glossário"
                  href="/glossario-de-agilidade/"
                >
                  Glossário de Agilidade
                </a>
              </li>
              <li>
                <a
                  title="Ir para a página da Pottencial"
                  aria-label="Ir para a página da Pottencial"
                  href="https://pottencial.com.br/"
                >
                  Site Pottencial
                </a>
              </li>
            </ul>
          </div>
          <div className="login-navigation">
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://portalcorretor.pottencial.com.br/Register"
                  rel="noopener noreferrer"
                >
                  SEJA NOSSO CORRETOR ➔
                </a>
              </li>
            </ul>
          </div>
          <div className="login2-navigation">
            <ul>
              <li>
                <button onClick={() => setMenuThree(!menuThree)}>LOGIN</button>
                <ul className={menuThree ? 'dropActiveOne' : ''}>
                  {menu3.map((item, index) => (
                    <li key={`submenu-3-${index}`}>
                      <a
                        title="Ir para a página"
                        aria-label="Ir para a página"
                        target="_blank"
                        href={item.link}
                        rel="noopener noreferrer"
                      >
                        <img src={item.icon} alt="" />
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={`NewHeader ${whiteMenu || menuWhite ? 'menuWhite' : ''}`}>
        <div className="Container">
          <div className="MenuLeft">
            <div className="logo">
              <a
                title="Ir para a página principal"
                aria-label="Ir para a página principal"
                href="/"
              >
                <img src={logoOrange} alt="Logo - Pottencial" />
              </a>
            </div>
          </div>

          <div className="MenuLeft">
            <ul>
              <li>
                <a
                  title="Ir para a página de Manifesto Ágil"
                  aria-label="Ir para a página de Manifesto Ágil"
                  href="/"
                >
                  MANIFESTO ÁGIL
                </a>
              </li>
              <li>
                <a
                  title="Ir para a página de Glossário"
                  aria-label="Ir para a página de Glossário"
                  href="/glossario-de-agilidade/"
                >
                  GLOSSÁRIO DE AGILIDADE
                </a>
              </li>
              <li>
                <a
                  title="Ir para a página da Pottencial"
                  aria-label="Ir para a página da Pottencial"
                  href="https://pottencial.com.br/"
                >
                  SITE POTTENCIAL
                </a>
              </li>
            </ul>
          </div>

          <div className="MenuRight">
            <a
              className="LinkRight"
              title="Ir para a página Portal do Corretor"
              aria-label="Ir para a página Portal do Corretor"
              target="_blank"
              href="https://portalcorretor.pottencial.com.br/Register"
              rel="noopener noreferrer"
            >
              Seja Nosso Corretor
            </a>

            <ul className="loginMenu">
              <li>
                <div className="buttonLogin">
                  <span>Login</span>
                </div>
                <ul>
                  <li>
                    <div className="LoginDropMenu">
                      <h4>Como deseja fazer login?</h4>
                      <a
                        className="LinkDropMenu"
                        title="Ir para a página Para Você"
                        aria-label="Ir para a página Para Você"
                        target="_blank"
                        href="https://paravoce.pottencial.com.br/"
                        rel="noopener noreferrer"
                      >
                        <img alt="Ícone pessoa" src={iconPessoa} />
                        Como Pessoa
                      </a>
                      <a
                        className="LinkDropMenu"
                        title="Ir para a página Portal Empresas"
                        aria-label="Ir para a página Portal Empresas"
                        target="_blank"
                        href="https://empresas.pottencial.com.br/"
                        rel="noopener noreferrer"
                      >
                        <img alt="Ícone Empresa" src={iconEmpresa} />
                        Como Empresa
                      </a>
                      <a
                        className="LinkDropMenu"
                        title="Ir para a página Portal do Corretor"
                        aria-label="Ir para a página Portal do Corretor"
                        target="_blank"
                        href="https://portalcorretor.pottencial.com.br/"
                        rel="noopener noreferrer"
                      >
                        <img alt="Ícone Corretor" src={iconCorretor} />
                        Como Corretor
                      </a>
                      <span className="SignupDropMenu">
                        Ainda não é Pottencial?
                        {' '}
                        <a
                          title="Ir para a página de Cadastro"
                          aria-label="Ir para a página de Cadastro"
                          target="_blank"
                          href="https://paravoce.pottencial.com.br/register"
                          rel="noopener noreferrer"
                        >
                          Cadastre-se
                        </a>
                      </span>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          {windowSize.width <= 1024 && (
            <button
              type="button"
              className="menu-toggler"
              title="Abrir menu"
              aria-label="Abrir menu"
              onClick={() => setActiveMenu(true)}
            >
              <span>MENU</span>
              {whiteMenu ? (
                <img src={iconMenuOrange} alt="Ícone - Pottencial" />
              ) : (
                <img src={iconMenu} alt="Ícone - Pottencial" />
              )}
            </button>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
