import React from 'react'
import work from '../../assets/images/work-group.svg'

const Guidelines = () => (
  <div className="statement guide">
    <div className="statement-scd">
      <div className="col small-12 xlarge-12 large-6">
        <div>
          <h2>Nossas diretrizes</h2>
        </div>
        <div />
        <div>
          <img src={work} alt="Nossas diretrizes - Pottencial" />
        </div>
      </div>

      <div className="col small-12 xlarge-12 large-6">
        <div className="guidelines">
          <div>
            <div>1</div>
          </div>
          <div>
            <b>Consideramos o cliente como centro da nossa estratégia </b>
            <span>
              e por isso acreditamos na cocriação de soluções que gerem valor,
              que proporcionem uma jornada diferenciada e um relacionamento de
              longo prazo.
            </span>
          </div>
        </div>

        <div className="guidelines">
          <div>
            <div>2</div>
          </div>
          <div>
            <b>Buscamos aperfeiçoar continuamente nossos produtos, </b>
            <span>
              processos e desenvolver pessoas por meio da cultura de feedback.
            </span>
          </div>
        </div>

        <div className="guidelines">
          <div>
            <div>3</div>
          </div>
          <div>
            <b>Apoiamos um ambiente colaborativo, que promova inovação, </b>
            <span>
              troca de ideias e experiências, em prol de um propósito comum.
            </span>
          </div>
        </div>

        <div className="guidelines">
          <div>
            <div>4</div>
          </div>
          <div>
            <b>Valorizamos pessoas movidas por desafios e adaptáveis, </b>
            <span>
              que estão sempre em busca de conhecimento e são
              capazes de responder a mudanças constantes.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Guidelines
