import React from 'react'
import Header from '../components/Glossary/Header'
import Footer from '../components/Footer'
import Content from '../components/Glossary/Content'

const terms = [
  {
    reference: 'a',
    contents: [
      {
        title: 'Agile Coach',
        desc: 'Seu papel é promover a cultura ágil na organização através de uma atuação transversal nos times, com foco na estratégia, melhoria contínua e entrega de valor.',
        images: [],
      },
    ],
  },
  {
    reference: 'c',
    contents: [
      {
        title: 'Cycle Time',
        desc: 'É o tempo de cada etapa de desenvolvimento, ou seja, o tempo entre partes consecutivas .',
        images: [],
      },
    ],
  },
  {
    reference: 'd',
    contents: [
      {
        title: 'Daily',
        desc: 'Reunião diária onde todos os membros do projeto devem comunicar o que fizeram desde a última reunião e o que estão se comprometendo a fazer na próxima reunião. Além de alinhar os pontos da Sprint, a Daily Scrum é importante porque mantém a comunicação constante entre a equipe.',
        images: [],
      },
      {
        title: 'Design Sprint',
        desc: 'É um processo criativo com cinco fases com restrição de tempo que utiliza o design thinking com o objetivo de reduzir o risco ao trazer um novo produto, serviço ou recurso ao mercado. ',
        images: [],
      },
      {
        title: 'Design Thinking',
        desc: 'Conjunto de ideias e insights para abordar problemas relacionados a futuras aquisições de informações, análise de conhecimento e propostas de soluções. Como uma abordagem, é considerada a capacidade para combinar empatia em um contexto de um problema, de forma a colocar as pessoas no centro do desenvolvimento de um projeto; criatividade para geração de soluções e razão para analisar e adaptar as soluções para o contexto. ',
        images: [],
      },
    ],
  },
  {
    reference: 'e',
    contents: [
      {
        title: 'Épicos (Epic)',
        desc: 'É uma grande história de usuário que deve ser dividida em várias histórias menores de forma que possam ser estimadas, priorizadas e desenvolvidas.',
        images: [],
      },
    ],
  },
  {
    reference: 'f',
    contents: [
      {
        title: 'Feedback',
        desc: 'O termo vem da língua inglesa e significa retroalimentação, realimentação ou retorno a determinado acontecimento. Feedbacks ajudam a diminuir erros e evitar retrabalhos, seja no desenvolvimento de produtos.',
        images: [],
      },
    ],
  },
  {
    reference: 'h',
    contents: [
      {
        title: 'Histórias (User Stories)',
        desc: 'São itens do product backlog que representam parte do produto a ser implementado. As histórias devem conter uma descrição das necessidades dos usuários e os critérios de aceitação.',
        images: [],
      },
    ],
  },
  {
    reference: 'j',
    contents: [
      {
        title: 'Jornada do Usuário (User Journey)',
        desc: 'É uma representação do relacionamento do cliente com um produto ou serviço em todas as suas etapas. A partir da jornada do usuário é possível compreender o que o cliente sente, pensa e faz em cada fase de contato com a solução apresentada para corrigir erros e encontrar oportunidades.',
        images: [],
      },
    ],
  },
  {
    reference: 'k',
    contents: [
      {
        title: 'Kanban',
        desc: 'O Método Kanban é um meio de desenhar, gerenciar e melhorar sistemas de fluxo para o trabalho do conhecimento. O método também permite que as organizações comecem com seu fluxo de trabalho existente e conduzam mudanças evolutivas. Eles podem fazer isso visualizando seu fluxo de trabalho, limitando o trabalho em andamento (WIP) e parando de iniciar e começando a terminar.',
        images: [],
      },
      {
        title: 'KPI ( Key Performance Indicator)',
        desc: 'Significa indicador-chave de desempenho. É uma forma de medir se uma ação ou um conjunto de iniciativas está efetivamente atendendo aos objetivos propostos pela organização.',
        images: [],
      },
    ],
  },
  {
    reference: 'l',
    contents: [
      {
        title: 'Lead Time',
        desc: 'É o tempo total de desenvolvimento, ou seja, é o tempo levado para conduzir o ciclo completo, desde o pedido até a entrega efetiva.',
        images: [],
      },
      {
        title: 'Lean',
        desc: 'Trata-se de um corpo de conhecimento cuja essência é a capacidade de eliminar desperdícios continuamente e resolver problemas de maneira sistemática. Isso implica repensar a maneira como se lidera, gerencia e desenvolve pessoas.',
        images: [],
      },
    ],
  },
  {
    reference: 'm',
    contents: [
      {
        title: 'MMP (Mínimo Produto Comercializável)',
        desc: 'Minimum Marketable Product (Mínimo produto comercializável) difere do MVP pois aqui o produto já está pronto para o mercado.',
        images: [],
      },
      {
        title: 'MLP (Mínimo Produto Amável)',
        desc: 'Minimum lovable product (Mínimo produto amável) como próprio nome diz significa ter o produto amado por uma certa audiência de clientes e isso significa que eles não conseguem viver sem o produto, pois ele já é parte do cliente. As mínimas funcionalidades causam comoção nos usuários que utilizarem o produto.',
        images: [],
      },
      {
        title: 'MVP (Mínimo Produto Viável)',
        desc: 'Versão de um novo produto que permite que uma equipe colete a quantidade máxima de aprendizado validado sobre clientes com o mínimo de esforço.',
        images: [],
      },
    ],
  },
  {
    reference: 'o',
    contents: [
      {
        title: 'OKR (Objectives and Key Results)',
        desc: 'A definição de "OKRs" é "Objectives and Key Results (Objetivos e Resultados chave”. É uma ferramenta de definição de metas colaborativa usada por equipes e indivíduos para definir objetivos desafiadores e ambiciosos com resultados mensuráveis. OKRs é como você monitora o progresso, cria alinhamento e incentiva o envolvimento em torno de metas mensuráveis.',
        images: [],
      },
    ],
  },
  {
    reference: 'p',
    contents: [
      {
        title: 'Planning Poker',
        desc: 'Técnica para a estimativa das histórias que normalmente utiliza a sequência de fibonacci e tem como objetivo estimar os itens do Sprint Backlog de uma sprint.',
        images: [],
      },
      {
        title: 'Problem Solving',
        desc: 'É uma metodologia de resolução de problemas. Ela utiliza o ciclo PDCA para esclarecer o problema, encontrar sua causa raiz e propor soluções de impacto mais efetivas no processo. É um modo mais prático e organizado de alcançar melhores resultados e evitar que as situações indesejadas voltem a ocorrer.',
        images: [],
      },
      {
        title: 'Product Backlog',
        desc: 'Lista de itens ou histórias que precisam ser implementadas para a criação do produto desejado ou para o desenvolvimento do projeto.',
        images: [],
      },
    ],
  },
  {
    reference: 's',
    contents: [
      {
        title: 'Scrum',
        desc: 'Um framework dentro do qual pessoas podem tratar e resolver problemas complexos e adaptativos com o mais alto valor possível.',
        images: [],
      },
      {
        title: 'Squad',
        desc: 'Time multidisciplinar responsável por todos os aspectos de um determinado projeto ou produto.',
        images: [],
      },
      {
        title: 'Squad Lead',
        desc: 'Seu papel é ser um líder servidor, removendo impedimentos e direcionando o time nas entregas de valor alinhadas a estratégia do negócio.',
        images: [],
      },
      {
        title: 'Sprint',
        desc: 'O coração do Scrum é a Sprint, um ciclo de tempo fechado "time-boxed" podendo ser um mês ou menos, durante o qual algum valor é incrementado ao produto em desenvolvimento.',
        images: [],
      },
      {
        title: 'Sprint Backlog',
        desc: 'É uma lista de atividades que precisam ser feitas durante uma sprint. No início de cada Sprint, olha-se para o Product Backlog e “puxa-se” o que será feito no Sprint. Essas “histórias” do Product Backlog, são agora desmembradas em atividades para serem executadas no Sprint (Sprint Tasks).',
        images: [],
      },
      {
        title: 'Sprint Burndown',
        desc: 'O Burndown chart ou gráfico de Burndown é o gráfico utilizado pelas equipes Scrum para representar diariamente o progresso do trabalho em desenvolvimento. Ou seja, após cada dia de trabalho o gráfico apresenta a porção de trabalho finalizada em comparação com o trabalho total planejado em um time box definido.',
        images: [],
      },
      {
        title: 'Sprint Planning',
        desc: 'O trabalho a ser realizado na Sprint é planejado na reunião de planejamento da Sprint. Este plano é criado com o trabalho colaborativo de todo o Time Scrum. Essa reunião possui um time-box com no máximo oito horas para uma Sprint de um mês de duração. Para Sprints menores, este evento é usualmente menor.',
        images: [],
      },
      {
        title: 'Sprint Review',
        desc: 'É realizado ao final de cada Sprint. Nesse rito o time demonstra tudo que foi desenvolvido com objetivo de coletar feedbacks. Normalmente estão na Sprint Review o Product Owner, Scrum Master e Stakeholders.',
        images: [],
      },
      {
        title: 'Sprint Retrospective',
        desc: 'Ocorre ao final de cada Sprint e serve para identificar o que funcionou bem durante o ciclo, o que pode ser melhorado e que ações serão tomadas para melhorar.',
        images: [],
      },
      {
        title: 'Stakeholder',
        desc: 'Definido um funcionário, cliente ou cidadão envolvido com uma organização, sociedade etc. e, portanto, tenha responsabilidades em relação a ela e interesse em seu sucesso.',
        images: [],
      },
    ],
  },

  {
    reference: 't',
    contents: [
      {
        title: 'Trade-off',
        desc: 'Trade-off é uma troca onde você desprioriza uma coisa para conseguir outra coisa que deseja ainda mais. Um produto enxuto reflete decisões da equipe em relação a trade-offs.',
        images: [],
      },
      {
        title: 'Tech Lead',
        desc: 'Seu papel é atuar na liderança técnica, com participação no desenvolvimento de soluções e garantir a qualidade técnica com foco na entrega de valor alinhadas a estratégia do negócio.',
        images: [],
      },
      {
        title: 'Tribo',
        desc: 'Grupo de squads que possuem objetivos similares e funcionalidades correlacionadas.',
        images: [],
      },
    ],
  },
  {
    reference: 'u',
    contents: [
      {
        title: 'Usuário Chave (User Key)',
        desc: 'Seu papel é o de participar efetivamente da definição das regras de negócio, alinhamento entre as partes interessadas e homologação das funcionalidades desenvolvidas pelo time.',
        images: [],
      },
      {
        title: 'UI Design (User Interface)',
        desc: 'A sigla UI significa User Interface, em português, Interface do Usuário. Ela representa tudo aquilo que é utilizado na interação com um produto. O UI Designer é responsável principalmente pela criação de interfaces funcionais que proporcione uma boa usabilidade e interação com usuário.',
        images: [],
      },
      {
        title: 'UX Design (User Experience)',
        desc: 'O profissional de UX conduz workshops e dinâmicas de cocriação, faz pesquisas com usuários, desenha wireframes e umas das suas principais atividades é mapear e entender a jornada do usuário para definir o melhor fluxo de funcionamento do produto ou sistema, a fim de impactar positivamente sua experiência.',
        images: [],
      },
    ],
  },
  {
    reference: 'w',
    contents: [
      {
        title: 'Work in Progress - WIP',
        desc: 'WIP significa Work In Progress (Trabalho em Progresso). É o número de demandas que um time possui em um determinado momento. Ele mostra a capacidade do fluxo de trabalho do time a qualquer dado momento.',
        images: [],
      },
    ],
  },
]

const Glossary = () => (
  <>
    <Header terms={terms} />
    <Content terms={terms} />
    <Footer />
  </>
)

export default Glossary
