import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Home from './Pages/Home'
import Glossary from './Pages/Glossary'

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/glossario-de-agilidade" component={Glossary} />
        </Switch>
      </BrowserRouter>
    </>
  )
}

export default App
